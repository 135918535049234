import Avatar from 'components/avatar'
import Button from 'components/button/button'
import { Dropdown } from 'components/dropdown/dropdown'
import Flex from 'components/layout/flex'
import { Table } from 'components/table/table'
import { ITableColumn } from 'components/table/table.types'
import Tag from 'components/tag/tag'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { format } from 'date-fns'
import {
  Purchase,
  PurchaseEnum,
  useConvertPurchaseOrderToPurchaseMutation,
  useMarkPurchaseAsVoidMutation,
  usePurchaseExportMutation,
  usePurchasesQuery,
} from 'generated/__generated_graphql'
import useDisclosure from 'hooks/useDisclosure'
import { useAppProvider } from 'providers/app-provider'
import { useState } from 'react'
import {
  BiAlarmExclamation,
  BiBookOpen,
  BiCheckCircle,
  BiEditAlt,
  BiXCircle,
} from 'react-icons/bi'
import { HiDotsHorizontal, HiOutlinePlus } from 'react-icons/hi'
import { useLocation } from 'react-router-dom'
import {
  downloadFileFromUrl,
  extractGraphqlErrors,
  formatMoney,
  getApiUrl,
} from 'utils/helpers'
import PurchaseProvider from '../providers/purchase-provider'
import DeletePurchaseOrderDrawer from './delete-expense'
import ExpenseDetail from './expense-detail'
import RecordExpensePayment from './expense-payment'
import { NewExpenseDrawer } from './new-expenses'
import SendPurchase from './send-purchase'
import React from 'react'

interface ExpensesTableProps {
  type: PurchaseEnum
}

export const ExpensesTable: React.FC<ExpensesTableProps> = ({ type }) => {
  const shouldOpenDrawer = useLocation().search.includes('openDrawer=true')
  const [, setDownloading] = useState(false)

  const isExpenses = type === PurchaseEnum.Purchase

  const notify = useToast()
  const purchaseDownloadUrl = getApiUrl()

  const { organisation } = useAppProvider()

  const [currentPurchase, setCurrentPurchase] = useState<Purchase>()
  const [page, setPage] = useState(1)
  const { isOpen: showPaymentDrawer, toggle: togglePaymentDrawer } =
    useDisclosure()
  // const { isOpen: showExpenseDrawer, toggle: toggleExpenseDrawer } =
  //   useDisclosure(shouldOpenDrawer)
  const [showExpenseDrawer, toggleExpenseDrawer] =
    React.useState(shouldOpenDrawer)
  const { isOpen: showPreviewDrawer, toggle: toggleShowPreviewDrawer } =
    useDisclosure()
  const { isOpen: showDeleteDrawer, toggle: toggleShowDeleteDrawer } =
    useDisclosure()
  const { isOpen: showSendDrawer, toggle: toggleShowSendDrawer } =
    useDisclosure()

  const [, convertToPurchaseMutation] =
    useConvertPurchaseOrderToPurchaseMutation()

  const [{ fetching: exporting }, downloadPurchases] =
    usePurchaseExportMutation()
  const [, voidPurchaseMutation] = useMarkPurchaseAsVoidMutation()

  const [{ fetching: loadingPurchases, data: purchases }, refreshPurchases] =
    usePurchasesQuery({
      variables: {
        purchaseType: type,
        status: null,
        pagination: {
          page,
          per: 20,
        },
      },
    })

  function onRecordPayment(data?: Purchase) {
    setCurrentPurchase(data)
    togglePaymentDrawer()
  }

  function onPreviewExpense(data?: Purchase) {
    setCurrentPurchase(data)
    toggleShowPreviewDrawer()
  }

  function onRemovePurchaseOrder(data?: Purchase) {
    setCurrentPurchase(data)
    toggleShowDeleteDrawer()
  }

  function onSendPurchase(data?: Purchase) {
    if (showPreviewDrawer) {
      toggleShowPreviewDrawer()
    }
    setCurrentPurchase(data)
    toggleShowSendDrawer()
  }

  async function downloadPreviewPdf(data?: Purchase) {
    try {
      setDownloading(true)
      await downloadFileFromUrl(
        `${purchaseDownloadUrl}/purchase/export/${data?.id}.pdf`,
        'purchase-pdf',
        'pdf',
        true
      )
      notify({
        content: 'Purchases downloaded successfully',
        status: 'success',
      })
      setDownloading(false)
    } catch {
      notify({ content: 'Something went wrong', status: 'error' })
      setDownloading(false)
    }
  }

  async function exportPurchases() {
    try {
      const response = await downloadPurchases({
        input: { export: true },
      })
      const error = extractGraphqlErrors(response, 'purchaseExport')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      const url = response?.data?.purchaseExport?.url
      const a = document.createElement('a')
      a.href = url as string
      a.click()
      URL.revokeObjectURL(a.href)

      notify({
        content: 'Purchases exported successfully',
        status: 'success',
      })
    } catch {
      notify({ content: 'Something went wrong', status: 'error' })
    }
  }

  async function onVoidPurchase(data?: Purchase) {
    try {
      const response = await voidPurchaseMutation({
        input: { purchaseId: data?.id ?? '' },
      })
      const error = extractGraphqlErrors(response, 'markPurchaseAsVoid')
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Purchase voided successfully',
        status: 'success',
      })
      refreshPurchases({ requestPolicy: 'network-only' })
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  async function convertToPurchase(purchase?: Purchase) {
    try {
      const response = await convertToPurchaseMutation({
        input: { purchaseOrderId: purchase?.id ?? '' },
      })
      const error = extractGraphqlErrors(
        response,
        'convertPurchaseOrderToPurchase'
      )
      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      notify({
        content: 'Purchase order converted to Purchase successfully',
        status: 'success',
      })
      refreshPurchases({ requestPolicy: 'network-only' })
    } catch {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
    }
  }

  function getExpenseActions(purchase: Purchase) {
    return [
      {
        label: 'Preview',
        onClick: () => onPreviewExpense(purchase),
        disabled: purchase.status === 'void',
      },
      {
        label: 'Record Payment',
        onClick: () => onRecordPayment(purchase),
        disabled: ['draft', 'paid', 'void'].includes(purchase.status ?? ''),
      },
      {
        label: 'Void',
        onClick: () => onVoidPurchase(purchase),
        disabled: ['partially_paid', 'paid', 'void', 'void'].includes(
          purchase.status ?? ''
        ),
      },
      {
        label: 'Download',
        onClick: () => downloadPreviewPdf(purchase),
        disabled: purchase.status === 'void',
      },
    ]
  }

  function getPurchaseOrderActions(purchase: Purchase) {
    return [
      {
        label: 'Preview',
        onClick: () => onPreviewExpense(purchase),
      },
      {
        label: 'Convert to Bill',
        onClick: () => convertToPurchase(purchase),
      },
      {
        label: 'Send',
        onClick: () => onSendPurchase(purchase),
      },
      {
        label: 'Download',
        onClick: () => downloadPreviewPdf(purchase),
      },
      {
        label: 'Remove',
        onClick: () => onRemovePurchaseOrder(purchase),
      },
    ]
  }

  function getActions(expense: Purchase) {
    return type === PurchaseEnum.Purchase
      ? getExpenseActions(expense)
      : getPurchaseOrderActions(expense)
  }

  const pagination = purchases?.purchases?.pagination

  const currency = organisation?.currency.symbol

  const columns: ITableColumn<Purchase>[] = [
    {
      key: 'id',
      title: 'Reference',
      dataIndex: 'reference',
      render: (ref) => (
        <Text size="xs" color="$primary">
          {ref}
        </Text>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          type={
            ['partially_paid', 'paid', 'approved'].includes(status)
              ? 'blue'
              : 'default'
          }
        >
          <Flex align="center" gutterX="1" justify="between">
            {['partially_paid', 'paid', 'approved'].includes(status) && (
              <BiCheckCircle size="1.75rem" />
            )}
            {status === 'void' && <BiXCircle size="1.75rem" />}
            {status === 'draft' && <BiEditAlt size="1.75rem" />}
            {status === 'open' && <BiBookOpen size="1.75rem" />}
            {status === 'overdue' && <BiAlarmExclamation size="1.75rem" />}

            {status === 'partially_paid' ? 'Partially paid' : status}
          </Flex>
        </Tag>
      ),
    },
    {
      key: 'supplier',
      title: 'Supplier',
      dataIndex: 'supplier',
      render: (supplier) => (
        <Flex align="center" gutterX="2">
          <Avatar title={supplier.name} size="medium" />
          <Text size="xs">{supplier.name}</Text>
        </Flex>
      ),
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => (
        <Text size="xs" color="$primary">
          {formatMoney(amount, currency)}
        </Text>
      ),
    },

    {
      key: 'dueDate',
      title: 'Due Date',
      dataIndex: 'dueDate',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'issueDate',
      title: 'Created',
      dataIndex: 'issueDate',
      render: (date) => (
        <Text size="xs" color="$primary">
          {format(new Date(date), 'dd MMM, yyyy')}
        </Text>
      ),
    },
    {
      key: 'action',
      title: '',
      dataIndex: 'status',
      render: (status, purchase) => (
        <Flex stretchx justify="end">
          <Dropdown placement="bottomRight" menu={getActions(purchase)}>
            <Flex stretchx align="center" justify="end">
              <Button appearance="ghost">
                <HiDotsHorizontal size="1.6rem" color="#ABB3B9" />
              </Button>
            </Flex>
          </Dropdown>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Table
        loading={loadingPurchases}
        showSearch
        columns={columns}
        dataSource={purchases?.purchases?.data ?? []}
        actions={
          <Flex gutterX="2">
            {/* <Button
              size="xl"
              appearance="secondary"
              disabled={exporting}
              isLoading={exporting}
              append={<HiArrowCircleUp size="1.3rem" color="#ABB3B9" />}
              onClick={exportPurchases}
            >
              Export
            </Button> */}
            <Button
              size="xl"
              prepend={<HiOutlinePlus color="#fff" />}
              // onClick={toggleExpenseDrawer}
              onClick={() => toggleExpenseDrawer(!showExpenseDrawer)}
            >
              {isExpenses ? 'Create New Bill' : 'Create New Purchase Order'}
            </Button>
          </Flex>
        }
        pagination={{
          totalCount: pagination?.totalCount as number,
          currentPage: pagination?.currentPage as number,
          perPage: 10,
          totalPages: pagination?.totalPages as number,
          onPaginationClick: (type) => {
            if (type === 'next') {
              if (page === pagination?.totalPages) return
              setPage(page + 1)
            } else {
              if (page === 1) return
              setPage(page - 1)
            }
          },
        }}
        emptyProps={{
          type: 'cta',
          title: `${
            isExpenses
              ? 'Create your first bill or expense'
              : 'Create your first purchase order'
          }`,
          subtitle: `${
            isExpenses
              ? 'Record all your expenses here, alter their statuses as and when with very little fuss.'
              : 'Create purchase orders to get the order confirmations at the most competitive rates from your vendors/suppliers before making payment'
          }`,
          action: (
            <Button
              // onClick={toggleExpenseDrawer}
              onClick={() => toggleExpenseDrawer(!showExpenseDrawer)}
              prepend={<HiOutlinePlus color="#fff" />}
              size="xl"
            >
              {isExpenses ? 'Create New Bill' : 'Create New Purchase Order'}
            </Button>
          ),
        }}
      />
      <PurchaseProvider>
        <NewExpenseDrawer
          visible={showExpenseDrawer}
          // onClose={toggleExpenseDrawer}
          onClose={() => toggleExpenseDrawer(!showExpenseDrawer)}
          onSuccess={() => refreshPurchases({ requestPolicy: 'network-only' })}
          type={type}
        />
      </PurchaseProvider>
      {currentPurchase && (
        <RecordExpensePayment
          purchase={currentPurchase}
          visible={showPaymentDrawer}
          onSuccess={() => refreshPurchases({ requestPolicy: 'network-only' })}
          onClose={togglePaymentDrawer}
        />
      )}
      {currentPurchase && (
        <ExpenseDetail
          visible={showPreviewDrawer}
          onClose={toggleShowPreviewDrawer}
          expense={currentPurchase}
          onSend={() => onSendPurchase(currentPurchase)}
        />
      )}
      {currentPurchase && (
        <DeletePurchaseOrderDrawer
          visible={showDeleteDrawer}
          onClose={toggleShowDeleteDrawer}
          purchase={currentPurchase}
          onSuccess={() => refreshPurchases({ requestPolicy: 'network-only' })}
        />
      )}
      {currentPurchase && (
        <SendPurchase
          visible={showSendDrawer}
          onClose={toggleShowSendDrawer}
          purchase={currentPurchase}
        />
      )}
    </>
  )
}
