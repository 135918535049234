import AnimatedNumber from 'animated-number-react'
import Button from 'components/button/button'
import DateInput from 'components/date-input/date-input'
import Col from 'components/layout/col'
import Flex from 'components/layout/flex'
import Row from 'components/layout/row'
import PageHeader from 'components/page-header/page-header'
import { Text } from 'components/text/text'
import Tooltip from 'components/tooltip'
import { addDays } from 'date-fns'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { useAppProvider } from 'providers/app-provider'
import React from 'react'
import { FaFileInvoiceDollar } from 'react-icons/fa6'
import { HiCalendar, HiChevronDown } from 'react-icons/hi'
import { PiReceiptFill } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { formatMoney, greeting } from 'utils/helpers'
import offerImg from '../../assets/png/Offers.png'
import avatar from '../../assets/png/avatar.png'
import { StyledBox, StyledDisplay } from './overview.styles'
import { useReportOverviewQuery } from 'generated/__generated_graphql'
import BarChart from 'pages/reports/components/bar-chart'
import { BiSolidPurchaseTag } from 'react-icons/bi'

interface OverviewPageProps {}

const OverviewPage: React.FC<OverviewPageProps> = () => {
  const { organisation, profile, overviewData, setOverviewDate, overviewDate } =
    useAppProvider()
  const currency = organisation?.currency?.symbol as string
  const navigate = useNavigate()

  const [{ data }] = useReportOverviewQuery()
  const reportOverview = data?.reportOverview

  const reportsLabels = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.monthYear
  ) as string[]
  const reportsDataA = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.revenue
  ) as number[]
  const reportsDataB = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.expenses
  ) as number[]
  const reportsDataC = reportOverview?.yearProfitRevenue?.map(
    (item) => item?.netProfit
  ) as number[]

  return (
    <>
      <PageHeader
        title="Overview"
        extra={
          <Flex align="center" gutter="4">
            <DateInput
              prepend={
                <Flex align="center" gutterX={1}>
                  <HiCalendar color="#ABB3B9" />
                  <Text size="xs" color="$secondary">
                    Date:
                  </Text>{' '}
                </Flex>
              }
              append={<HiChevronDown size="1.9rem" color="#ABB3B9" />}
              maximumDate={addDays(new Date(), 0)}
              required
              placeholder="Select Report Date"
              isMonthPicker
              defaultValue={overviewDate}
              value={overviewDate}
              onChange={(e) => {
                setOverviewDate(new Date(e.target.value))
              }}
            />
          </Flex>
        }
      />
      <StyledLayoutContent>
        <Flex direction="column" gutter="3">
          <Row gutter="3">
            <Col span={12}>
              <StyledDisplay>
                <Flex className="left" direction="column" gutter="2">
                  <Flex align="center" gutter="2">
                    <img src={avatar} alt="" className="avatar" />
                    <Text size="xl" weight="bold" color="$black">
                      {greeting()} {profile?.firstName}!
                    </Text>
                  </Flex>
                  <Text
                    size="xxs"
                    weight="bold"
                    color="$gray"
                    css={{ letterSpacing: '.5rem', fontSize: 10, mt: '$3' }}
                  >
                    QUICK ACTIONS
                  </Text>
                  <Flex align="center" gutter="2">
                    <Button
                      appearance="secondary"
                      prepend={<BiSolidPurchaseTag />}
                      onClick={() =>
                        navigate('/dashboard/purchases/orders?openDrawer=true')
                      }
                    >
                      Create new PO
                    </Button>
                    <Button
                      appearance="secondary"
                      prepend={<FaFileInvoiceDollar />}
                      onClick={() =>
                        navigate('/dashboard/sales/invoices?openDrawer=true')
                      }
                    >
                      Create new Invoice
                    </Button>
                    <Button
                      appearance="secondary"
                      prepend={<PiReceiptFill />}
                      onClick={() =>
                        navigate(
                          '/dashboard/purchases/expenses?openDrawer=true'
                        )
                      }
                    >
                      Create new Bill
                    </Button>
                  </Flex>
                </Flex>

                <Flex direction="column" className="right">
                  <img className="img" src={offerImg} alt="" />
                </Flex>
              </StyledDisplay>
            </Col>
          </Row>
        </Flex>

        <Flex direction="column" gutter="3" css={{ mt: '$6' }}>
          <Text size="xs" weight="bold" color="$secondary">
            Cash outflows
          </Text>
          <StyledBox css={{ height: '35rem' }}>
            <BarChart
              labels={reportsLabels}
              dataA={reportsDataA}
              dataB={reportsDataB}
              dataC={reportsDataC}
              currency={currency}
            />
          </StyledBox>
        </Flex>
        <Flex direction="column" gutter="3" css={{ mt: '$6' }}>
          <Text size="xs" weight="bold" color="$secondary">
            Business Performance
          </Text>
          <Row gutter="3">
            <Col span={4}>
              <CardDisplay
                title="Revenue"
                amount={String(overviewData?.overview?.revenue)}
                currency={currency}
                subTitle="this month"
                tooltip="This shows the total payments that have been made to your account within the month."
              />
            </Col>
            <Col span={4}>
              <CardDisplay
                title="OPEX"
                amount={String(overviewData?.overview?.opex)}
                currency={currency}
                subTitle="this month"
                tooltip="This is the money your business has spent on an ongoing, day-to-day basis to run its business for the month."
              />
            </Col>
            <Col span={4}>
              <CardDisplay
                title="Net Profit/Loss"
                amount={String(overviewData?.overview?.netProfitOrLoss)}
                currency={currency}
                subTitle="this month"
                tooltip="This is the difference between the gross profit or loss and the total indirect income/expenses of your business within the month."
              />
            </Col>
          </Row>
        </Flex>
      </StyledLayoutContent>
    </>
  )
}

export default OverviewPage

interface CardDisplayProps {
  children?: React.ReactNode
  title: string
  subTitle?: string
  amount?: string
  tooltip: string
  currency?: string
  percentage?: number
  number?: number
}

function CardDisplay({
  children,
  title,
  amount,
  subTitle,
  tooltip,
  currency,
  percentage,
  number,
}: CardDisplayProps) {
  return (
    <StyledBox>
      <Flex direction="column" justify="between">
        <Flex align="center" gutter={2}>
          <Text size="xs" weight="bold" color="$gray">
            {title}
          </Text>

          <Tooltip align="center" placement="top" description={tooltip}>
            <svg
              width="13"
              height="12"
              viewBox="0 0 13 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 0C2.9104 0 0 2.68749 0 6C0 9.31444 2.9104 12 6.5 12C10.0896 12 13 9.31444 13 6C13 2.68749 10.0896 0 6.5 0ZM6.5 10.8387C3.60299 10.8387 1.25806 8.67506 1.25806 6C1.25806 3.32673 3.6031 1.16129 6.5 1.16129C9.39593 1.16129 11.7419 3.32591 11.7419 6C11.7419 8.67411 9.39798 10.8387 6.5 10.8387ZM6.5 2.66129C7.10796 2.66129 7.60081 3.11623 7.60081 3.67742C7.60081 4.23861 7.10796 4.69355 6.5 4.69355C5.89204 4.69355 5.39919 4.23861 5.39919 3.67742C5.39919 3.11623 5.89204 2.66129 6.5 2.66129ZM7.96774 8.80645C7.96774 8.96678 7.82692 9.09677 7.65323 9.09677H5.34677C5.17308 9.09677 5.03226 8.96678 5.03226 8.80645V8.22581C5.03226 8.06548 5.17308 7.93548 5.34677 7.93548H5.66129V6.3871H5.34677C5.17308 6.3871 5.03226 6.2571 5.03226 6.09677V5.51613C5.03226 5.3558 5.17308 5.22581 5.34677 5.22581H7.02419C7.19788 5.22581 7.33871 5.3558 7.33871 5.51613V7.93548H7.65323C7.82692 7.93548 7.96774 8.06548 7.96774 8.22581V8.80645Z"
                fill="#A1AAB7"
              />
            </svg>
          </Tooltip>
        </Flex>

        {number ? (
          <Text
            size="lg"
            weight="semi"
            css={{ mt: '$2', fontFamily: '$space', fontSize: '2.4rem' }}
            color="$secondary"
          >
            {`${number ?? 0} months`}
          </Text>
        ) : (
          <>
            {(percentage as number) >= 0 ? (
              <Text
                size="lg"
                weight="semi"
                css={{ mt: '$2', fontFamily: '$space', fontSize: '2.4rem' }}
                color="$secondary"
              >
                {`${percentage ?? 0}%`}
              </Text>
            ) : (
              <AnimatedNumber
                easing="linear"
                value={amount}
                formatValue={(value: number) => formatMoney(value, currency)}
                duration={1000}
                className="animated-number"
              />
            )}
          </>
        )}

        {subTitle && (
          <Text size="xxs" weight="semi" css={{ mt: '$1' }} color="$gray">
            {subTitle}
          </Text>
        )}
        {children && (
          <Flex
            align="center"
            justify="between"
            gutter={5}
            direction="column"
            css={{ mt: 50 }}
            stretchx
          >
            {children}
          </Flex>
        )}
      </Flex>
    </StyledBox>
  )
}
