import PageHeader from 'components/page-header/page-header'
import { Tabs } from 'components/tabs/tabs'
import { StyledLayoutContent } from 'pages/dashboard/components/layouts/layouts.styles'
import { ContactsTable } from './components/contacts-table'
import { StyleContent } from './contacts.styles'

export const Contacts = () => {
  return (
    <>
      <PageHeader title="Vendors" />
      <StyledLayoutContent>
        {/* <PageBanner
          title="View and manage your contacts"
          description="Contacts are people you do business with. You can add contacts to your account and manage them here."
          actionText="Learn more"
          bgSvg={background}
        /> */}
        <StyleContent>
          <Tabs
            activeKey="1"
            tabs={[
              {
                key: '1',
                title: 'All Vendors',
                content: <ContactsTable />,
              },
            ]}
          />
        </StyleContent>
      </StyledLayoutContent>
    </>
  )
}
