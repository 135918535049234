import { zodResolver } from '@hookform/resolvers/zod'
import { ReactComponent as AccounteerLogo } from 'assets/svg/logo.svg'
import { ReactComponent as BgPattern } from 'assets/svg/bg-pattern.svg'
import Button from 'components/button/button'
import Input from 'components/input/input'
import Flex from 'components/layout/flex'
import Separator from 'components/separator/separator'
import { Text } from 'components/text/text'
import { useToast } from 'components/toast'
import { motion } from 'framer-motion'
import {
  LoginStrategyEnum,
  useLoginMutation,
} from 'generated/__generated_graphql'
import { GoogleAuthenticationButton } from 'hooks/useGoogleAuthentication'
import React from 'react'
import { useForm } from 'react-hook-form'
import { HiChevronLeft } from 'react-icons/hi'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { setToken } from 'utils/auth'
import { extractGraphqlErrors } from 'utils/helpers'
import { email, password } from 'utils/schemas'
import { wayFinder } from 'utils/wayfinder'
import { z } from 'zod'
import {
  StyledCol,
  StyledContent,
  StyledLink,
  StyledLogoContainer,
  StyledRow,
} from '../auth.styles'
import { Footer } from '../components/footer/footer'

const formSchema = z.object({
  email,
  password,
})

type FormFields = z.infer<typeof formSchema>

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const notify = useToast()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  React.useEffect(() => {
    if (token) {
      setToken(token)
      navigate('/dashboard')
    }
  }, [token])

  const [, loginUserMutation] = useLoginMutation()

  const {
    register,
    formState: { errors, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<FormFields>({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
  })

  async function onSubmit() {
    try {
      const { email, password } = getValues()
      const response = await loginUserMutation({
        input: {
          email,
          password,
          strategy: LoginStrategyEnum.Plain,
        },
      })
      const { login } = response.data ?? {}
      const { id } = login?.user?.organisation ?? {}
      const { plan } = login?.user?.organisation ?? {}

      const error = extractGraphqlErrors(response, 'login')

      if (error) {
        notify({ content: error, status: 'error' })
        return
      }
      const token = login?.token
      if (token) {
        setToken(token)
        if (localStorage.getItem('lastPath')) {
          navigate(localStorage.getItem('lastPath') || '/dashboard')
          localStorage.removeItem('lastPath')
          return
        }
        navigate(wayFinder({ id, plan }, '/dashboard'))
      }
    } catch (error) {
      notify({
        content: 'Something went wrong. Please try again',
        status: 'error',
      })
      return
    }
  }

  return (
    <StyledRow gutter={1}>
      <BgPattern className="pattern" />
      <StyledCol span={4}>
        <motion.div
          style={{
            minHeight: '100%',
          }}
          initial={{ opacity: 0, x: -300 }}
          animate={{ opacity: 1, x: 0, transition: { duration: 0.25 } }}
          exit={{ opacity: 0, x: -300 }}
        >
          <StyledContent>
            <StyledLogoContainer align="center" justify="between">
              <AccounteerLogo style={{ width: '7rem' }} />
              <Flex align="center" gutter={1}>
                <HiChevronLeft color="#ABB3B9" size="1.5rem" />
                <Text size="xxs">Don&apos;t have an account?</Text>
                <StyledLink to={!isSubmitting ? '/register' : ''}>
                  {' '}
                  Register
                </StyledLink>
              </Flex>
            </StyledLogoContainer>
            <>
              <Flex className="flex" gutterY={2} direction={'column'}>
                <Text size="lg">Welcome back</Text>
                <Text color="$secondary" size="xs">
                  Get back in to track, grow and scale your business
                </Text>
              </Flex>

              <Flex
                justify={'between'}
                css={{ width: '100%', gap: '1rem', mt: '5rem' }}
              >
                <GoogleAuthenticationButton
                  mode="sign-in"
                  disabled={isSubmitting}
                />
              </Flex>

              <Flex align={'center'} css={{ mt: '1rem' }}>
                <Separator color="$disabled" />
                <Text
                  size="xxs"
                  weight="bold"
                  color="gray"
                  css={{
                    whiteSpace: 'nowrap',
                    mx: '1.5rem',
                  }}
                >
                  or
                </Text>
                <Separator color="$disabled" />
              </Flex>

              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Flex gutterY={4} direction={'column'}>
                  <Input
                    {...(register('email', {
                      setValueAs: (value) => (value as string).toLowerCase(),
                    }) as any)}
                    required
                    label="Email Address"
                    placeholder="Enter your email address"
                    error={errors.email?.message}
                  />

                  <Input
                    {...(register('password') as any)}
                    required
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    error={errors.password?.message}
                  />

                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                  >
                    Sign in
                  </Button>

                  <Flex gutterX={1} justify="center" align="center">
                    <Text size="xxs">Forgot your password?</Text>
                    <Link to={!isSubmitting ? '/forgot-password' : ''}>
                      <Text color="$blue" size="xxs">
                        Reset password
                      </Text>
                    </Link>
                  </Flex>
                  {/* <Flex gutterX={1} justify="center">
                    <Text size="xs">Don&apos;t have an account?</Text>
                    <Link
                      to={!isSubmitting ? '/register' : ''}
                      className="get-started"
                    >
                      <Text size="xs">Get Started</Text>
                    </Link>
                  </Flex> */}
                </Flex>
              </form>
              <Footer
                justify="center"
                align="center"
                direction="column"
                gutterY={1}
                extraMargin
              />
            </>
          </StyledContent>
        </motion.div>
      </StyledCol>
      {/* <AuthBackground /> */}
    </StyledRow>
  )
}

export default Login
